<template>
  <AuthContainer :title="'404找不到頁面'" @onBack="handleBack">
    <AuthBackContainer :title="'404找不到頁面'">
        <!--ErrorPage-->
        <article class="rounded-t-2xl h-inherit pt-20 md:pt-0 audit _abnormal md:bg-white">
          <div class="audit-wrap">
            <section class="audit-ill">
              <h2 class="audit-tit">ErrorPage</h2>
              <figure class="md:w-200 md:mx-auto md:my-4 md:pt-200"></figure>
            </section>
            <section class="audit-con">
              <p>找不到此頁面</p>
            </section>
            <router-link :to="{ name: 'home' }" class="btn-forms py-2 px-24">
              回首頁
            </router-link>
          </div>
        </article>
    </AuthBackContainer>
  </AuthContainer>
</template>
<script>
import AuthContainer from '@/components/auth/AuthContainer'
import AuthBackContainer from '@/components/auth/AuthBackContainer-noPadding'

export default {
  name: 'errorPage',
  components: {
    AuthContainer,
    AuthBackContainer
  },
  methods: {
    handleBack() {
      this.$router.push('/')
    }
  }
}
</script>