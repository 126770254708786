<template>
  <section class="relative md:pb-14">
    <article class="bg-pink-salmon-200 md:bg-white pt-14 md:pt-8 md:max-w-1200 md:mx-auto h-screen">
      <header class="relative hidden md:block">
        <h2 class="text-3xl font-bold text-center mb-8">{{title}}</h2>
        <p class="text-lg -mt-3 absolute top-1/2 left-0">
          <router-link :to="{name: 'login'}" class="flex items-center">
            <a-icon type="arrow-left" class="mr-2" />回首頁
          </router-link>
        </p>
      </header>
        <slot></slot>
    </article>
  </section>
</template>


<script>

export default {
  props:{
      title: {
      default: '註冊',
      type: String
    },
    
  },
  data() {
    return {}
  },
  }
</script>

<style scoped>
.icon-back:before {
  content: "\e906";
}
</style>